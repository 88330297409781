// Entry point for the build script in your package.json

// import '@rails/actioncable' // we don't use action cable on storefront
import '@hotwired/turbo-rails'
import { Application } from '@hotwired/stimulus'

import './utilities/sentry'
import './utilities/iframely'
import ahoy from 'ahoy.js'
window.ahoy = ahoy
const metaTitle = document.querySelector('meta[property="og:title"]')
if (metaTitle) {
  window.ahoy.configure({ visitParams: { landing_page_title: metaTitle.content } })
}

// Stimulus - Setup
const application = Application.start()
application.debug = false
window.Stimulus = application

// Stimulus - Controllers
import CartController from "./controllers/cart_controller"
import QuantityPickerController from "./controllers/quantity_picker_controller"
import AddressFormController from "./controllers/address_form_controller"
import EnableButtonController from "./controllers/enable_button_controller"
import ToggleSearchController from "./controllers/toggle_search_controller"
import FormController from "./controllers/form_controller"
import ProductFormController from "./controllers/storefront/product_form_controller"
import LightboxController from "./controllers/storefront/lightbox_controller"
import StickyButtonController from "./controllers/storefront/sticky_button_controller"
import CopyInputController from "./controllers/copy_input_controller"
import ToggleMenuController from "./controllers/toggle_menu_controller"
import HeaderController from './controllers/header_controller'
import ClearInputController from './controllers/clear_input_controller'
import NoUiSliderController from './controllers/storefront/no_ui_slider_controller'
import SearchableListController from './controllers/storefront/searchable_list_controller'
import SlideoverController from './controllers/storefront/slideover_controller'
import AccordionController from './controllers/storefront/accordion_controller'
import PlpVariantPickerController from './controllers/storefront/plp_variant_picker_controller'
import SearchSuggestionsController from './controllers/storefront/search_suggestions_controller'
import AccountNavController from "./controllers/storefront/account_nav_controller"
import TurboStreamFormController from './controllers/turbo_stream_form_controller'
import WishedItemController from './controllers/storefront/wished_item_controller'
import StripePaymentMethodMessagingElementController from './controllers/storefront/stripe_payment_method_messaging_element_controller'
import PDPDesktopGallery from "./controllers/storefront/pdp_desktop_gallery"
import InfiniteScrollController from './controllers/storefront/infinite_scroll_controller'
import CheckoutStripeButtonController from './controllers/checkout/stripe_button_controller'
import DeliveryDatesController from './controllers/storefront/delivery_dates_controller'
import DropdownController from './controllers/storefront/dropdown_controller'
import MobileNavController from './controllers/storefront/mobile_nav_controller'
import RecaptchaFormController from './controllers/recaptcha_form'

application.register('cart', CartController)
application.register("quantity-picker", QuantityPickerController)
application.register("address-form", AddressFormController)
application.register("enable-button", EnableButtonController)
application.register("toggle-search", ToggleSearchController)
application.register("form", FormController)
application.register("product-form", ProductFormController)
application.register("lightbox", LightboxController)
application.register('accordion', AccordionController);
application.register("sticky-button", StickyButtonController)
application.register("copy-input", CopyInputController)
application.register("toggle-menu", ToggleMenuController)
application.register("header", HeaderController)
application.register("clear-input", ClearInputController)
application.register('no-ui-slider', NoUiSliderController)
application.register('searchable-list', SearchableListController)
application.register('slideover', SlideoverController)
application.register('slideover-account', SlideoverController)
application.register('plp-variant-picker', PlpVariantPickerController)
application.register('search-suggestions', SearchSuggestionsController)
application.register('account-nav', AccountNavController)
application.register('turbo-stream-form', TurboStreamFormController)
application.register('wished-item', WishedItemController)
application.register('stripe-payment-method-messaging-element', StripePaymentMethodMessagingElementController)
application.register('pdp-desktop-gallery', PDPDesktopGallery)
application.register('infinite-scroll', InfiniteScrollController)
application.register('checkout-stripe-button', CheckoutStripeButtonController)
application.register('delivery-dates', DeliveryDatesController)
application.register('dropdown', DropdownController)
application.register('mobile-nav', MobileNavController)
application.register('recaptcha-form', RecaptchaFormController)

// Import and register all TailwindCSS Components
import { Alert, Toggle, Modal } from 'tailwindcss-stimulus-components'
application.register('alert', Alert)
application.register('toggle', Toggle)
application.register('modal', Modal)

import Reveal from 'stimulus-reveal-controller'
application.register('reveal', Reveal)

import ScrollTo from 'stimulus-scroll-to'
application.register('scroll-to', ScrollTo)

// 3rd party controllers
import Swiper from './controllers/swiper_controller'
application.register('carousel', Swiper)

import ReadMore from 'stimulus-read-more'
application.register('read-more', ReadMore)

const scrollToOverlay = (overlay) => {
  const { top, left } = overlay.getBoundingClientRect()

  window.scroll({
    behavior: 'smooth',
    top: window.scrollY + top - window.innerHeight / 2 + overlay.offsetHeight / 2,
    left: left + window.scrollX
  })
}

// page builder UI
const toggleHighlightEditorOverlay = (query) => {
  const overlay = document.querySelector(query)

  if (overlay) {
    if (overlay.classList.contains('editor-overlay-hover')) {
      overlay.classList.remove('editor-overlay-hover')
    } else {
      overlay.classList.add('editor-overlay-hover')

      scrollToOverlay(overlay)
    }
  }
}

const makeOverlayActive = (id) => {
  const overlay = document.querySelector(`.editor-overlay[data-editor-id="${id}"]`)

  document.querySelectorAll('.editor-overlay-active').forEach(el => {
    el.classList.remove('editor-overlay-active')
  })

  if (overlay) {
    overlay.classList.add('editor-overlay-active')
    scrollToOverlay(overlay)
  }
}

const toggleHighlightElement = (id) => {
  toggleHighlightEditorOverlay(`.editor-overlay[data-editor-id="${id}"]`)
}

window.scrollToOverlay = scrollToOverlay
window.toggleHighlightElement = toggleHighlightElement
window.makeOverlayActive = makeOverlayActive

document.addEventListener('turbo:submit-start', () => {
  Turbo.navigator.delegate.adapter.progressBar.setValue(0)
  Turbo.navigator.delegate.adapter.progressBar.show()
})

document.addEventListener('turbo:submit-end', () => {
  Turbo.navigator.delegate.adapter.progressBar.setValue(100)
  Turbo.navigator.delegate.adapter.progressBar.hide()
})

function replaceCsrfMetaTags() {
  const csrfMetaTagsTemplate = document.querySelector('template#csrf_meta_tags')
  if (!csrfMetaTagsTemplate) return

  const csrfMetaTags = csrfMetaTagsTemplate.content.cloneNode(true)

  document.head.querySelectorAll('meta[name="csrf-token"]').forEach((tag) => tag.remove())
  document.head.querySelectorAll('meta[name="csrf-param"]').forEach((tag) => tag.remove())

  document.head.appendChild(csrfMetaTags)
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', replaceCsrfMetaTags)
} else {
  replaceCsrfMetaTags()
}
